<template>
    <div>
        <keep-alive>
            <router-view></router-view>
        </keep-alive>



    </div>
</template>



<script>
import Cookies from 'js-cookie'
import {getMovieArticle} from "@/api/index"
import { Loading ,Message } from 'element-ui';
export default {
    data(){
        return {
            articlelist:[],
            total:null,
            pageSize:8,
            currPageNo:1,
            articleload:true,
            t : null,
            clientHeight : null,
            scrollHeight : null,
            load : null,
        }
    },
    methods:{
        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
    },
    mounted(){

    },
    beforeDestroy (){
        window.removeEventListener('scroll',this.windowscroll)
    },
    filters:{
        dataFilter:function(val){
            if (!val) return ''
            return val.substring(0,10)
        }
    },
}
</script>

<style lang="scss" scoped>


</style>